








































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import AddIcon from '@/app/ui/assets/add_icon.vue'
import AddCircleIcon from '@/app/ui/assets/add_circle.vue'
import TrashIcon from '@/app/ui/assets/trash_icon_alt.vue'
import controller from '@/app/ui/controllers/PayrollController'
import { Setting, Tier } from '@/domain/entities/Payroll'
import { LocalStorage } from '@/app/infrastructures/misc'
import ModalConfirm from '../../../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../../../components/Modals/ModalSuccess/index.vue'
import PayrollTextInput from '../../../components/PayrollTextInput/index.vue'
import DropdownMultiSelect from '../../../components/DropdownMultiSelect/index.vue'
import {
  shipmentPrefixOptions,
  sttPrefixOptions,
  codMultiplyOptions,
} from '@/app/infrastructures/misc/Constants/manageDataMaster'
import WarningCircleIcon from '@/app/ui/assets/ics_f_warning_circle_red.vue'
import CODFeeInput from '../../../components/CODFeeInput/index.vue'

interface Options {
  label: string
  value: string
}

interface IRule {
  [k: string]: Options | ISetting[] | undefined
}

interface isValidation {
  [k: string]: unknown
}

interface ISetting {
  isDefault: boolean
  prefix: string[]
  setting: ITier[]
}

interface ITier {
  minWeight: number
  multiplyType?: Options
  fee: number
  parkingFee: number
  codMultiplyType?: Options
  codFee: number
}

interface RulePayload {
  calculation_method?: string
  delivery_tier?: SettingPayload[]
  pickup_tier?: SettingPayload[]
  product_type?: string
}

interface SettingPayload {
  prefix: string[]
  setting: TierPayload[]
}

interface TierPayload {
  fee?: number
  min_weight?: number
  multiply_type?: string
  parking_fee?: number
  cod_multiply_type?: string
  cod_fee?: number
}

interface PeriodRule {
  basicFee: {
    calculationMethod: Array<string>
    productType: Array<string>
    multiplyType: Array<string>
    rules: Array<Record<string, string | Array<Tier>>>
  }
  basicFeeId: number
}

@Component({
  mixins: [validationMixin],
  components: {
    DropdownSelect,
    Button,
    AddIcon,
    AddCircleIcon,
    TrashIcon,
    Loading,
    ModalConfirm,
    ModalSuccess,
    PayrollTextInput,
    DropdownMultiSelect,
    WarningCircleIcon,
    CODFeeInput,
  },
})
export default class BasicFeePage extends Vue {
  controller = controller
  currentPeriod = ''
  basicFeeId = ''
  modalConfirmVisible = false
  modalConfirmDeleteVisible = false
  modalSuccessVisible = false
  isValidate = false
  selectedRuleIndex = 0

  calcMethodOptions: Array<Options> = []
  productOptions: Array<Options> = []
  multiplyOptions: Array<Options> = []

  ruleData: Array<IRule> = []

  shipmentPrefixOptions = shipmentPrefixOptions
  sttPrefixOptions = sttPrefixOptions
  codMultipleOptions = codMultiplyOptions

  created(): void {
    this.currentPeriod = LocalStorage.getLocalStorage('selectedPeriod')
    this.fetchData()
  }

  get filteredProductTypeOpt(): Array<Options> {
    const selectedProduct = this.ruleData.map(rd => rd.productType)

    return this.productOptions.filter(cm => !selectedProduct.includes(cm))
  }

  private getHeaders(tierType: string) {
    const headers = ['Berat Minimal', 'Type (KG/FLAT)']

    if (tierType === 'pickup') {
      headers.push('Biaya Pick Up')
      headers.push('Biaya Pick Up - Parkir')
      headers.push('Biaya COD')
    } else if (tierType === 'delivery') {
      headers.push('Biaya Delivery')
      headers.push('Biaya Delivery - Parkir')
      headers.push('Biaya COD')
    }

    return headers
  }

  private fetchData() {
    controller.getDetail({
      id: this.$route.params.periodId,
      page: parseInt(<string>this.$route.params.page),
    })
  }

  private generateOptions(list: string[], labelFormat: string) {
    const res = list.map(method => {
      let label = method

      if (labelFormat === 'capitalize')
        label = `${label.charAt(0).toUpperCase()}${label.substring(1)}`
      if (labelFormat === 'uppercase') label = label.toUpperCase()

      return {
        label: label,
        value: method,
      }
    })

    return res
  }

  private filterShipmentPrefixOptions(
    ruleIndex: number,
    prefixes: string[]
  ): Options[] {
    const selectedPrefix = (<ISetting[]>(
      this.ruleData[ruleIndex].pickupTier
    )).map(tier => tier.prefix.map(prefix => prefix))
    let mergedPrefix: string[] = []

    selectedPrefix.forEach(prefix => {
      prefix.forEach(prefixString => {
        mergedPrefix.push(prefixString)
      })
    })

    mergedPrefix = mergedPrefix.filter(prefix => !prefixes.includes(prefix))

    const finalSelectedPrefix = this.shipmentPrefixOptions.filter(option => {
      return !mergedPrefix.includes(option.label)
    })

    return finalSelectedPrefix
  }

  private filterSTTPrefixOptions(
    ruleIndex: number,
    prefixes: string[]
  ): Options[] {
    const selectedPrefix = (<ISetting[]>(
      this.ruleData[ruleIndex].deliveryTier
    )).map(tier => tier.prefix.map(prefix => prefix))
    let mergedPrefix: string[] = []

    selectedPrefix.forEach(prefix => {
      prefix.forEach(prefixString => {
        mergedPrefix.push(prefixString)
      })
    })

    mergedPrefix = mergedPrefix.filter(prefix => !prefixes.includes(prefix))

    const finalSelectedPrefix = this.sttPrefixOptions.filter(option => {
      return !mergedPrefix.includes(option.label)
    })

    finalSelectedPrefix

    return finalSelectedPrefix
  }

  private checkIsSelectAllPrefix(
    prefixes: string[],
    option: Options[]
  ): boolean {
    return prefixes.length === option.length
  }

  private onAddNewTierToList(
    ruleIndex: number,
    tierType: string,
    tierIndex: number
  ) {
    ;(<ISetting[]>this.ruleData[ruleIndex][tierType])[tierIndex].setting.push({
      fee: 0,
      minWeight: 0,
      parkingFee: 0,
      multiplyType: this.multiplyOptions[0],
      codMultiplyType: this.codMultipleOptions[0],
      codFee: 0,
    })
  }

  private onDeleteTierFromList(
    ruleIndex: number,
    tierType: string,
    tierIndex: number,
    prefixIndex: number
  ) {
    ;(<ISetting[]>this.ruleData[ruleIndex][tierType])[tierIndex].setting.splice(
      prefixIndex,
      1
    )
    controller.setIsChanged(true)
  }

  private onDeleteProductFromList() {
    this.ruleData.splice(this.selectedRuleIndex, 1)
    this.selectedRuleIndex = 0
    this.modalConfirmDeleteVisible = false
    controller.setIsChanged(true)

    this.$notify({
      title: 'Delete Product Type',
      text: 'Product Type is successfully deleted',
      type: 'success',
      duration: 3000,
    })
  }

  private onOpenDeleteModal(ruleIndex: number) {
    this.selectedRuleIndex = ruleIndex
    this.modalConfirmDeleteVisible = true
  }

  private onAddNewPrefix(
    ruleIndex: number,
    tierType: 'pickupTier' | 'deliveryTier'
  ): void {
    ;(<ISetting[]>this.ruleData[ruleIndex][tierType]).push({
      isDefault: false,
      prefix: [],
      setting: [
        {
          fee: 0,
          minWeight: 0,
          parkingFee: 0,
          multiplyType: this.multiplyOptions[0],
          codMultiplyType: this.codMultipleOptions[0],
          codFee: 0,
        },
      ],
    })
  }

  private onDeletePrefix(
    ruleIndex: number,
    tierType: 'pickupTier' | 'deliveryTier',
    tierIndex: number
  ): void {
    ;(<ISetting[]>this.ruleData[ruleIndex][tierType]).splice(tierIndex, 1)
  }

  private onAddNewRuleToList() {
    this.ruleData.push({
      calculationMethod: this.calcMethodOptions[0],
      deliveryTier: [
        {
          isDefault: true,
          prefix: [],
          setting: [
            {
              fee: 0,
              minWeight: 0,
              parkingFee: 0,
              multiplyType: this.multiplyOptions[0],
              codMultiplyType: this.codMultipleOptions[0],
              codFee: 0,
            },
          ],
        },
      ],
      pickupTier: [
        {
          isDefault: true,
          prefix: [],
          setting: [
            {
              fee: 0,
              minWeight: 0,
              parkingFee: 0,
              multiplyType: this.multiplyOptions[0],
              codMultiplyType: this.codMultipleOptions[0],
              codFee: 0,
            },
          ],
        },
      ],
      productType: this.filteredProductTypeOpt[0],
    })
  }

  private onChangeCurrency(
    e: number | string,
    ruleIndex: number,
    tierType: 'pickupTier' | 'deliveryTier',
    tierIndex: number,
    fieldType: 'fee' | 'parkingFee' | 'codFee',
    prefixIndex: number
  ) {
    let newValue = 0
    if (typeof e === 'string') {
      newValue = 0
    } else {
      newValue = e
    }

    if (fieldType === 'fee') {
      ;(<ISetting[]>this.ruleData[ruleIndex][tierType])[tierIndex].setting[
        prefixIndex
      ].fee = newValue
    } else if (fieldType === 'parkingFee') {
      ;(<ISetting[]>this.ruleData[ruleIndex][tierType])[tierIndex].setting[
        prefixIndex
      ].parkingFee = newValue
    }

    controller.setIsChanged(true)
  }

  private onSubmit() {
    this.modalConfirmVisible = false
    this.isValidate = true
    let rulesPayload: Array<RulePayload> = []

    if (!this.$v.ruleData.$invalid) {
      this.ruleData.forEach(data => {
        if (data.calculationMethod) {
          rulesPayload.push({
            calculation_method: (data.calculationMethod as Options).value,
            delivery_tier: (<ISetting[]>data.deliveryTier).map(dt => {
              return {
                prefix: <string[]>(
                  dt.prefix.map(
                    prefix =>
                      this.sttPrefixOptions.find(
                        option => option.label === prefix
                      )?.value
                  )
                ),
                setting: dt.setting.map((tier: ITier) => {
                  return {
                    fee: tier.fee,
                    min_weight: tier.minWeight,
                    multiply_type: tier.multiplyType?.value,
                    parking_fee: tier.parkingFee,
                    cod_multiply_type: tier.codMultiplyType?.value,
                    cod_fee: tier.codFee,
                  }
                }),
              }
            }),
            pickup_tier: (<ISetting[]>data.pickupTier).map(pt => {
              return {
                prefix: <string[]>(
                  pt.prefix.map(
                    prefix =>
                      this.shipmentPrefixOptions.find(
                        option => option.label === prefix
                      )?.value
                  )
                ),
                setting: pt.setting.map((tier: ITier) => {
                  return {
                    fee: tier.fee,
                    min_weight: tier.minWeight,
                    multiply_type: tier.multiplyType?.value,
                    parking_fee: tier.parkingFee,
                    cod_multiply_type: tier.codMultiplyType?.value,
                    cod_fee: tier.codFee,
                  }
                }),
              }
            }),
            product_type: (data.productType as Options)?.value,
          })
        }
      })

      if (rulesPayload) {
        const payload = {
          id: this.basicFeeId,
          rules: {
            rules: rulesPayload,
            product_type: this.productOptions?.map(p => p.value),
            multiply_type: this.multiplyOptions?.map(m => m.value),
            calculation_method: this.calcMethodOptions?.map(cm => cm.value),
          },
        }
        controller.updatePeriodBasicFee(payload)
      }
    } else {
      this.$notify({
        title: 'Edit Basic Fee Failed',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private checkTierIsUnique(value: Tier, values: Array<Tier>) {
    let isUnique = true
    const firstIndex = values.findIndex(v => v.minWeight === value.minWeight)

    values.forEach((weight: Tier, index: number) => {
      if (index !== firstIndex) {
        if (weight.minWeight === value.minWeight) {
          isUnique = false
        }
      }
    })

    return isUnique
  }

  private checkTierIsHeavier(value: Tier, values: Array<Tier>) {
    let isHeavier = true
    const valueIndex = values.findIndex(v => v.minWeight === value.minWeight)

    if (valueIndex === 0) {
      return isHeavier
    }

    const prevWeight = values[valueIndex - 1].minWeight
    const currWeight = value.minWeight

    if (currWeight !== undefined && prevWeight !== undefined) {
      if (currWeight < prevWeight) {
        isHeavier = false
      }
    }

    return isHeavier
  }

  private checkCODFeePercentage(
    value: number,
    vm: { codMultiplyType: Options }
  ): boolean {
    if (vm.codMultiplyType.value === this.codMultipleOptions[1].value) {
      return value >= 0 && value <= 100
    }

    return true
  }

  private tierValidation(
    isValidate: boolean,
    ruleIndex: number,
    tierType: 'pickupTier' | 'deliveryTier',
    tierIndex: number,
    prefixIndex: number
  ) {
    const rules = this.$v.ruleData.$each

    if (isValidate && rules) {
      const rule = rules[ruleIndex]
      if (rule) {
        const tier = rule[tierType].$each[tierIndex]?.setting.$each[prefixIndex]
        if (tier) {
          const isUnique = tier.isUnique
          const isHeavier = tier.isHeavier
          if (!isUnique) {
            return 'Angka sudah ada di dalam range'
          } else if (!isHeavier) {
            return 'Tidak boleh lebih kecil dari kolom sebelumnnya'
          }
        }
      }
    }

    return ''
  }

  private tierMapper(settingData: Setting[], isPickupTier = false): ISetting[] {
    return settingData.map((sd, index) => {
      return {
        isDefault: index === 0,
        prefix: sd.prefix?.map(prefix =>
          isPickupTier
            ? this.shipmentPrefixOptions.find(option => option.value === prefix)
                ?.label
            : this.sttPrefixOptions.find(option => option.value === prefix)
                ?.label
        ),
        setting: sd.setting?.map(tier => {
          return {
            fee: tier.fee || 0,
            minWeight: tier.minWeight || 0,
            parkingFee: tier.parkingFee || 0,
            multiplyType:
              this.multiplyOptions.find(
                opt => opt.value === tier.multiplyType
              ) || {},
            codMultiplyType:
              this.codMultipleOptions.find(
                opt => opt.value === tier.codMultiplyType
              ) || this.codMultipleOptions[0],
            codFee: tier.codFee || 0,
          }
        }),
      }
    }) as ISetting[]
  }

  @Validations()
  validations(): isValidation {
    return {
      ruleData: {
        $each: {
          productType: { required },
          calculationMethod: { required },
          pickupTier: {
            $each: {
              prefix: {
                required: requiredIf(vm => {
                  return !vm.isDefault
                }),
              },
              setting: {
                $each: {
                  isUnique: this.checkTierIsUnique,
                  isHeavier: this.checkTierIsHeavier,
                  multiplyType: { required },
                  fee: { required },
                  parkingFee: { required },
                  codMultiplyType: { required },
                  codFee: {
                    required,
                    isPercentage: this.checkCODFeePercentage,
                  },
                },
              },
            },
          },
          deliveryTier: {
            $each: {
              prefix: {
                required: requiredIf(vm => {
                  return !vm.isDefault
                }),
              },
              setting: {
                $each: {
                  isUnique: this.checkTierIsUnique,
                  isHeavier: this.checkTierIsHeavier,
                  multiplyType: { required },
                  fee: { required },
                  parkingFee: { required },
                  codMultiplyType: { required },
                  codFee: {
                    required,
                    isPercentage: this.checkCODFeePercentage,
                  },
                },
              },
            },
          },
        },
      },
    }
  }

  @Watch('controller.dataPeriodRule')
  setDataPeriodRule(data: PeriodRule): void {
    this.ruleData = []
    const basicFee = data.basicFee

    if (data.basicFeeId) {
      this.basicFeeId = String(data.basicFeeId)
    }

    if (basicFee?.calculationMethod) {
      this.calcMethodOptions = this.generateOptions(
        basicFee?.calculationMethod,
        'capitalize'
      )
    }

    if (basicFee?.productType) {
      this.productOptions = this.generateOptions(
        basicFee?.productType,
        'capitalize'
      )
    }

    if (basicFee?.multiplyType) {
      this.multiplyOptions = this.generateOptions(
        basicFee?.multiplyType,
        'uppercase'
      )
    }

    if (basicFee?.rules) {
      const basicFeeRules = basicFee.rules as Array<
        Record<string, string | Array<Tier>>
      >

      basicFeeRules.forEach(rule => {
        const deliveryTier = this.tierMapper(<Setting[]>rule.deliveryTier)
        const pickupTier = this.tierMapper(<Setting[]>rule.pickupTier, true)

        const res: IRule = {
          calculationMethod: this.calcMethodOptions.find(
            opt => opt.value === rule.calculationMethod
          ),
          deliveryTier: deliveryTier,
          pickupTier: pickupTier,
          productType:
            this.productOptions.find(opt => opt.value === rule.productType) ||
            <Options>{},
        }
        this.ruleData.push(res)
      })
    }
  }

  @Watch('controller.isEditPeriodBasicFeeSuccess')
  setEditPeriodBasicFeeSuccess(data: boolean): void {
    if (data) {
      this.modalSuccessVisible = true
      this.fetchData()
      controller.setIsChanged(false)
      controller.setIsEditPeriodBasicFeeSuccess(false)
      this.isValidate = false
    }
  }
}
